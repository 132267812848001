<template>
  <div class="readme-article">
    <h1 id="学生">2.学生</h1>
    <h2 id="回访方式">2.1回访方式</h2>
    <p>
      回访方式设置，例如：电话、家访等。
    </p>
    <p>
      <img src="@/assets/img/recruit/config2-1.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Recruit0-2",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>